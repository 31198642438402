<template>
  <div style="padding: 20px">
    <div class="d-flex">
      <v-checkbox
          v-for="(item, index) in items"
          :key="index"
          class="ml-5"
          :label="item.business"
          v-model="item.show"
      />
    </div>
    <div class="d-flex flex-wrap">
      <v-col
          cols="3"
          v-for="(item, index) in items"
          :key="index"
          v-show="item.show">
        <div class="c-one-sub-title">{{ item.business }}列表banner图</div>
        <material
            sub-title="图片建议：宽高1920*480像素，大小不能超过2MB，格式仅限于jpg、png"
            :show-add-icon="false"
            :value-image="item.bannerUrl"
            @change="item.bannerUrl = $event"/>
      </v-col>
    </div>
    <div
        style="
        background: #ffffff;
				bottom: 0;
				margin-left: -32px;
				position: fixed;
				width: 100%"
    >
      <div style="height: 1px; background: #e7e7e7; width: 100%;"></div>
      <div style="padding: 15px">
        <v-btn class="mr-4" depressed outlined color="primary" @click="reset">重置</v-btn>
        <v-btn depressed color="primary" @click="save">保存</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import material from "@/components/common-material/material.vue";

export default {
  data() {
    return {
      items: [],
      o_items: []
    }
  },
  components: {
    material
  },
  created() {
    this.axios.post('/api/menus/getbaseinfo', {tab: 4, type: "PC"}).then(res => {
      this.items = res.data.banners
      this.o_items = JSON.parse(JSON.stringify(this.items))
    })
  },
  methods: {
    reset() {
      this.items = this.o_items
    },
    save() {
      this.axios.post('/api/menus/save_baseinfo', {banners: this.items, type: 'PC'}).then(res => {
        this.snackbar.success('保存成功');
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c-one-title {
  font-size: 18px;
  font-weight: bold;
  border-left: 2px solid var(--v-primary-base);
  padding-left: 8px;
  color: #333333;
}

.c-one-sub-title {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin: 8px 0;
  white-space: nowrap;
}
</style>
